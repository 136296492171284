import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { sendEvent } from '@aph/components/gtm/useGtm';
import { Button } from '@aph/ui/components/button/button';
import { Typography } from '@aph/ui/components/typography/typography';
import { SideDrawer } from '~/articles/components/side-drawer/side-drawer';
import type { IFilterOption, IOption } from '~/articles/generated/ArticlesClient';
import { SelectedFilters } from './selected-filters.component';

type FilterDrawerProps = {
  open: boolean;
  onClose: () => void;
  filter: IFilterOption[] | undefined;
  totalCount?: number;
  onToggleOption: (filterOptionId: IFilterOption['id'], option: IOption) => void;
  onClearFilter: () => void;
};

export const FilterDrawer = ({
  open,
  onClose,
  filter,
  totalCount,
  onToggleOption,
  onClearFilter,
}: FilterDrawerProps) => {
  const { formatMessage } = useIntl();
  const filterHasSelectedOptions = (filterOption: IFilterOption) => {
    if (!filterOption.id) return false;

    return filterOption.options?.some((x) => x.isSelected);
  };

  const hasSelectedFilters = filter?.some(filterHasSelectedOptions);

  return (
    <SideDrawer
      title={formatMessage({ id: 'PRODUCT_LIST.FILTER.DRAWER.HEADING' })}
      isOpen={open}
      onClose={onClose}
    >
      <>
        {hasSelectedFilters ? (
          <div className="border-subtle border-b p-2">
            <Typography className="pb-2" asChild>
              <h2>
                <FormattedMessage id="PRODUCT_LIST.FILTER.DRAWER.CHOSEN_FILTER" />
              </h2>
            </Typography>
            <SelectedFilters filters={filter} onToggle={onToggleOption} onClear={onClearFilter} />
          </div>
        ) : null}

        <div className="bg-elevated flex-1 overflow-y-scroll">
          {filter
            ?.filter((x) => (x.options?.length ?? 0) > 0)
            ?.sort((a, b) => (a.order || 0) - (b.order || 0))
            .map((filterOption) => (
              <Accordion
                disableGutters
                key={`${filterOption.id}:${filterHasSelectedOptions(filterOption) ? 'expanded' : 'collapsed'
                  }`}
                defaultExpanded={filterHasSelectedOptions(filterOption)}
                sx={{
                  '&.MuiPaper-root': {
                    borderRadius: '0px',
                    border: 'none',
                    borderBottom: (theme) => `1px solid ${theme.palette['color/border/subtle']} `,
                    boxShadow: 'none',
                  },

                  '&:before': {
                    content: 'none',
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    '.MuiAccordionSummary-content': {
                      margin: '12px 0 !important',
                    },

                    '.MuiTypography-root': {
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '22px',
                      color: (theme) => theme.palette['color/text/default'],
                    },
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>{filterOption.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    {filterOption.options
                      ?.sort((a, b) => (a.order || 0) - (b.order || 0))
                      .map((option) => (
                        <div key={option.value}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  '&.Mui-checked': {
                                    color: (theme) => theme.palette['color/icon/action'],
                                  },
                                }}
                                checked={option.isSelected}
                                onChange={(event, isChecked) => {
                                  onToggleOption(filterOption.id, option);
                                  if (isChecked) {
                                    sendEvent({
                                      event: 'choose_product_filter_click',
                                      filter_category: filterOption.label,
                                      checkbox_value: option.name,
                                    });
                                  }
                                }}
                              />
                            }
                            label={option.name}
                          />
                        </div>
                      ))}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            ))}
        </div>

        <div className="mb-12 flex justify-center">
          <Button variant="secondary" onClick={onClose}>
            <FormattedMessage
              id="PRODUCT_LIST.FILTER.DRAWER.SHOW_RESULTS"
              values={{ count: totalCount }}
            />
          </Button>
        </div>
      </>
    </SideDrawer>
  );
};
