import React, { useEffect } from 'react';
import { Button } from '../button/button';
import { Icon } from '../icon/icon';
import { BaseDropdown } from './base';
import type { DropdownProps } from './dropdown';

type DropdownButtonProps = DropdownProps;

/**
 *
 * @example
 * ```tsx
 * <DropdownButton
 *  items="[...items]"
 *  selected={item}
 *  onSelected={(item) => setSelected(item)}
 *  iconName="ArrowheadDown"
 * />
 * ```
 *
 */
export const DropdownButton: React.FC<DropdownButtonProps> = ({ items, selected, onSelected }) => {
  const [current, setCurrent] = React.useState(selected);

  useEffect(() => {
    setCurrent(selected);
  }, [selected]);

  return (
    <BaseDropdown.Root>
      <Button variant="secondary" className="group" size="small" asChild>
        <BaseDropdown.Trigger>
          <Icon name="ArrowheadDown" size="small" className="group-data-[state=open]:rotate-180" />
          {current.title}
        </BaseDropdown.Trigger>
      </Button>
      <BaseDropdown.Content additionalWidth={20} align="end">
        {items
          .map((item) => ({ ...item, thumbnail: undefined }))
          .map((item) => (
            <BaseDropdown.Item
              key={item.value}
              item={item}
              currentValue={current.value}
              onClick={() => {
                setCurrent(item);
                onSelected?.(item);
              }}
            />
          ))}
      </BaseDropdown.Content>
    </BaseDropdown.Root>
  );
};
