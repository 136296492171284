import { useRouter } from 'next/router';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';
import { useGetSearchParams } from '../../../hooks/use-get-search-params/use-get-search-params';

export type ArticlePaginationButtonProps = {
  title: string;
  paginateTo: number;
  isLoading: boolean;
  onPaginate: () => void;
};

export const ArticlePaginationButton = ({
  title,
  paginateTo,
  isLoading,
  onPaginate,
}: ArticlePaginationButtonProps) => {
  const router = useRouter();
  const searchParams = useGetSearchParams();
  const href = useGetPagingButtonHref(paginateTo);

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (paginateTo < 2) {
      searchParams.delete('page');
    } else {
      searchParams.set('page', paginateTo.toString());
    }
    router.push({ query: searchParams.toString() }, undefined, {
      scroll: false,
      shallow: true,
    });
    onPaginate();
  };

  return (
    <Button
      data-pw="article-pagination-button"
      data-testid="ArticlePaginationButton"
      isLoading={isLoading}
      onClick={onClick}
      size="small"
      variant="secondary"
      asChild
    >
      <a href={href}>
        {title}
        <Icon size="small" name="Plus" />
      </a>
    </Button>
  );
};

const useGetPagingButtonHref = (page: number) => {
  const router = useRouter();
  const searchParams = useGetSearchParams(router.asPath);

  if (page < 2) {
    searchParams.delete('page');
  } else {
    searchParams.set('page', page.toString());
  }

  const path = router.asPath.split('?')[0];
  let query = searchParams.toString();

  if (query.length > 0) {
    query = `?${query}`;
  }

  return `${path}${query}`;
};
