import { Skeleton, Stack } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import type { IImageLink } from '~/contentful/types';
import { useGetSelectedFilterOptionReferences } from '../../../components/common/product-listing/filter.component';
import { useGetPageParam } from '../../hooks/use-get-page-param/use-get-page-param';
import { ArticleGrid, type ArticleGridProps } from '../article-grid/article-grid';
import { ArticlePaginationButton } from './article-pagination-button/article-pagination-button';
import { ArticleSorting, type ArticleSortingProps } from './article-sorting/article-sorting';

export type ArticleListingProps = {
  isLoading: boolean;
  isFetching: boolean;
  listing: ArticleGridProps;
  pagination: {
    hasNextPage: boolean | undefined;
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;
    hasPreviousPage: boolean | undefined;
    fetchPreviousPage: () => void;
    isFetchingPreviousPage: boolean;
  };
  sorting: ArticleSortingProps | undefined;
  filter?: React.ReactNode; // TEMP
  banner?: IImageLink | undefined;
};

export const ArticleListing = ({
  isLoading,
  isFetching,
  listing,
  pagination,
  sorting,
  filter,
  banner,
}: ArticleListingProps) => {
  const pageParam = useGetPageParam();
  const { messages } = useIntl();
  const filters = useGetSelectedFilterOptionReferences();

  const articleGridProps: ArticleGridProps = {
    ...listing,
    isFetching,
    isLoadingNext: isLoading,
    listName: listing.listName,
  };

  const hasArticles = !isLoading && (listing.totalCount ?? 0) > 0;
  const isFiltering = filters.length > 0;

  const shouldShowFilter = isFiltering || hasArticles;

  return (
    <Stack gap={3} alignItems="center">
      <Stack
        direction="row"
        width="100%"
        alignItems={filter ? 'flex-start' : 'flex-end'}
        justifyContent="space-between"
        marginBottom={1}
      >
        <Stack rowGap={1}>
          {shouldShowFilter && filter}
          <Typography typography="body" color="text-subtle">
            {isLoading ? (
              <Skeleton width="100px" />
            ) : (
              <FormattedMessage
                id="PRODUCT_LIST.PAGE.PRODUCTS"
                values={{ total: listing.totalCount }}
              />
            )}
          </Typography>
        </Stack>
        {hasArticles ? sorting && <ArticleSorting {...sorting} /> : null}
      </Stack>
      {pagination.hasPreviousPage && (
        <ArticlePaginationButton
          title={messages['PRODUCT_LIST.PAGINATION.SHOW_PREV'].toString()}
          isLoading={pagination.isFetchingPreviousPage}
          paginateTo={pageParam - 1}
          onPaginate={pagination.fetchPreviousPage}
        />
      )}
      <ArticleGrid {...articleGridProps} banner={banner} />
      {pagination.hasNextPage && (
        <ArticlePaginationButton
          title={messages['PRODUCT_LIST.PAGINATION.SHOW_MORE'].toString()}
          isLoading={pagination.isFetchingNextPage}
          paginateTo={pageParam + 1}
          onPaginate={pagination.fetchNextPage}
        />
      )}
      {hasArticles && (
        <Typography data-testid="ArticleListingCount" typography="body" color="text-subtle">
          <FormattedMessage
            id="PRODUCT_LIST.PAGINATION.COUNT"
            values={{ count: (listing.articles || []).length, totalCount: listing.totalCount }}
          />
        </Typography>
      )}
    </Stack>
  );
};
