import { useRouter } from 'next/router';

export function useGetSearchParams(url?: string) {
  const router = useRouter();
  const searchParams = new URLSearchParams(url?.split('?')[1]);

  if (url) {
    return searchParams;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(router.query)) {
    // eslint-disable-next-line no-continue
    if (!value) continue;
    if (Array.isArray(value)) {
      value.forEach((element) => {
        searchParams.append(key, element);
      });
    } else {
      searchParams.append(key, value);
    }
  }

  return searchParams;
}
