import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { cn } from '../../tailwind/cn';
import type { CustomVariantProps } from '../../tailwind/variant-props';
import { Typography } from '../typography/typography';

const variations = cva(
  'bg-elevated hover:bg-action-subtle border-focus aria-selected:bg-visual outline-focus flex items-center gap-1 px-2',
  {
    variants: {
      size: {
        default: 'py-1',
        small: 'py-0.5',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
);

export interface ListItemProps
  extends React.HTMLAttributes<HTMLElement>,
  CustomVariantProps<typeof variations> {
  title: string;
  withDivider?: boolean;
  children?: React.ReactNode;
  thumbnail?: React.ReactNode;
  asChild?: boolean;
}

/**
 *
 * @example
 * ```tsx
 * <ListItem title="This a title" thumbnail={<div>LI</div>} />
 * ```
 *
 */
export const ListItem: React.FC<ListItemProps> = ({ title, thumbnail, ...rest }) => (
  <ListItemRoot {...rest}>
    <ListItemThumbnail thumbnail={thumbnail} />
    <ListItemTitle title={title} />
  </ListItemRoot>
);

export const ListItemRoot: React.FC<Omit<ListItemProps, 'title' | 'thumbnail'>> = ({
  className,
  size,
  withDivider,
  children,
  asChild,
  ...rest
}) => {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={cn(variations({ size }), withDivider && 'border-default border-b', className)}
      {...rest}
    >
      {children}
    </Comp>
  );
};

export const ListItemThumbnail = ({ thumbnail }: Pick<ListItemProps, 'thumbnail'>) => {
  if (!thumbnail) {
    return null;
  }
  return <div className="h-[32px] w-[32px]">{thumbnail}</div>;
};

export const ListItemTitle = ({ title }: Pick<ListItemProps, 'title'>) => (
  <Typography>{title}</Typography>
);
