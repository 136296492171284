import React, { type ComponentPropsWithoutRef, useMemo } from 'react';
import { Chip, Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import { Icon } from '@aph/ui/components/icon/icon';
import { notEmpty } from '@aph/utilities/not-empty';
import type { IFilterOption, IOption } from '~/articles/generated/ArticlesClient';

const FilterChip = (props: ComponentPropsWithoutRef<typeof Chip>) => (
  <Chip
    {...props}
    sx={{
      backgroundColor: (theme) => theme.palette['color/background/visual'],
      color: (theme) => theme.palette['color/text/action-dark'],
      fontSize: '14px',
      height: '32px',
      padding: (theme) => theme.spacing(1, 1.5),
      '& .MuiChip-label': {
        padding: 'unset',
      },
      '& .MuiChip-icon': {
        order: 1,
        marginRight: '0px',
        marginLeft: '4px',
        fontSize: '1em',
      },
    }}
  />
);

interface SelectedFiltersProps {
  filters: IFilterOption[] | undefined;
  onToggle: (filterOptionId: IFilterOption['id'], option: IOption) => void;
  onClear: () => void;
}

export const SelectedFilters = ({ filters, onToggle, onClear }: SelectedFiltersProps) => {
  const intl = useIntl();

  const selectedFilterOptions = useMemo(
    () =>
      filters
        ?.map((filter) =>
          filter.options
            ?.filter((option) => option.isSelected)
            ?.map((selectedOption) => ({ ...selectedOption, filterOptionId: filter.id })),
        )
        ?.flat()
        .filter(notEmpty),
    [filters],
  );

  return (
    <Stack direction="row" flexWrap="wrap" gap={0.5}>
      {selectedFilterOptions?.map((option) => (
        <FilterChip
          key={`${option?.filterOptionId}_${option?.value}`}
          icon={<Icon name="Close" size="small" fill="fill-action-dark" />}
          label={option?.name}
          onClick={() => onToggle(option?.filterOptionId, option)}
        />
      ))}
      {(selectedFilterOptions?.length ?? 0) > 0 ? (
        <FilterChip
          label={intl.formatMessage({ id: 'PRODUCT_LIST.FILTER.DRAWER.CLEAR_FILTERS' })}
          onClick={onClear}
        />
      ) : null}
    </Stack>
  );
};
