import type { ListItemProps } from '../list-item/list-item';

export type DropdownItem = Pick<ListItemProps, 'title' | 'thumbnail'> & {
  value: string;
  subTitle?: string;
};

export interface DropdownProps {
  items: DropdownItem[];
  selected: DropdownItem;
  onSelected?: (item: DropdownItem) => void;
}

export type DropdownResult = DropdownProps['selected'];

export { DropdownButton } from './dropdown-button';
export { DropdownMenu } from './dropdown-menu';
