import { notEmpty } from '@aph/utilities/not-empty';
import type { IFilterOptionReference } from '../../generated/ArticlesClient';

const SEPARATOR = ':';

export const createFilterParam = (key: string, value: string) => `${key}${SEPARATOR}${value}`;

export const parseFilterParams = (
  params: string[] | string | undefined,
): IFilterOptionReference[] => {
  const allParams = Array.isArray(params) ? params : [params];
  return allParams.filter(notEmpty).map((param) => {
    const [filterId, filterValue] = param.split(SEPARATOR);
    return {
      id: filterId,
      selected: [filterValue],
    };
  });
};
