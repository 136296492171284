import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { sendEvent } from '@aph/components/gtm/useGtm';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';
import { notEmpty } from '@aph/utilities/not-empty';
import type {
  IFilter,
  IFilterOption,
  IFilterOptionReference,
  IOption,
} from '~/articles/generated/ArticlesClient';
import { Option } from '~/articles/generated/ArticlesClient';
import { useGetSearchParams } from '~/articles/hooks/use-get-search-params/use-get-search-params';
import {
  createFilterParam,
  parseFilterParams,
} from '~/articles/utilities/article-filter/article-filter';
import { FilterDrawer } from './filter-drawer.component';
import { SelectedFilters } from './selected-filters.component';

type FilterProps = {
  filter?: IFilter;
  totalCount?: number;
};

function useMapToSelectedFilterOptions(filterOptions: IFilterOption[]): IFilterOption[] {
  const selected = useGetSelectedFilterOptionReferences()
    .map((x) => {
      const value = x?.selected?.at(0);
      const id = x?.id;

      return id && value && `${id}:${value}`;
    })
    .filter(notEmpty);

  return filterOptions.map((filterOption) => ({
    ...filterOption,
    options: filterOption.options
      ?.filter((x) => !x.isSelected)
      .map((option) => {
        const isSelected = selected.some((value) => value === `${filterOption.id}:${option.value}`);

        return new Option({
          ...option,
          isSelected,
        });
      }),
  }));
}

export const Filter = ({ filter, totalCount }: FilterProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const router = useRouter();
  const searchParams = useGetSearchParams();
  const selectedFilterOptionReferences = useGetSelectedFilterOptionReferences();
  const selectedFilterOptions = useMapToSelectedFilterOptions(filter?.options ?? []);

  const totalNumberOfSelectedFilters = selectedFilterOptionReferences
    .map((x) => x.selected)
    .flat().length;

  const onClearFilter = () => {
    searchParams.delete('filter');
    router.replace({ query: searchParams.toString() }, undefined, { shallow: true });
  };

  const onToggleOption = (filterOptionId: IFilterOption['id'], option: IOption) => {
    if (!option.value || !filterOptionId) {
      return;
    }

    const selectedFilterOptionReference = selectedFilterOptionReferences.findIndex(
      (x) => x.id === filterOptionId && x.selected?.at(0) === option.value,
    );

    if (selectedFilterOptionReference > -1) {
      selectedFilterOptionReferences.splice(selectedFilterOptionReference, 1);
    } else {
      selectedFilterOptionReferences.push({ id: filterOptionId, selected: [option.value] });
    }

    searchParams.delete('filter');
    selectedFilterOptionReferences.forEach((param) => {
      const value = param?.selected?.at(0);

      if (param.id && value) {
        searchParams.append('filter', createFilterParam(param.id, value));
      }
    });

    router.replace({ query: searchParams.toString() }, undefined, { shallow: true });
  };

  return (
    <>
      <Stack direction={{ xs: 'column', md: 'row' }} alignItems="flex-start" gap={1}>
        <Button
          data-pw="filter-button"
          size="small"
          variant="secondary"
          onClick={() => {
            setIsFilterOpen(true);
            sendEvent({
              event: 'open_product_filter_click',
            });
          }}
        >
          <div>
            <FormattedMessage id="PRODUCT_LIST.FILTER.BUTTON_TEXT" />
            {totalNumberOfSelectedFilters > 0 ? ` (${totalNumberOfSelectedFilters})` : null}
          </div>
          <Icon name="Filter" size="small" />
        </Button>

        {totalNumberOfSelectedFilters > 0 ? (
          <Box mr={2}>
            <SelectedFilters
              filters={selectedFilterOptions}
              onToggle={onToggleOption}
              onClear={onClearFilter}
            />
          </Box>
        ) : null}
      </Stack>
      <FilterDrawer
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        filter={selectedFilterOptions}
        totalCount={totalCount}
        onToggleOption={onToggleOption}
        onClearFilter={onClearFilter}
      />
    </>
  );
};

export function useGetSelectedFilterOptionReferences(): IFilterOptionReference[] {
  const params = useGetSearchParams();
  return parseFilterParams(params.getAll('filter'));
}
