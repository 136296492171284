import type { InfiniteData } from '@tanstack/react-query';
import type { InfiniteQueryHookResult, QueryHookResult } from 'react-query-kit';
import { Filter } from '@aph/components/common/product-listing/filter.component';
import type {
  ArticleReferenceBaseResultCollection,
  IFilter,
  ISorting,
} from '~/articles/generated/ArticlesClient';
import type { ArticleListingProps } from './article-listing';

type ArticleResponse = { articles: ArticleReferenceBaseResultCollection };
type FilterAndSortingResponse = {
  sorting: ISorting;
  filtering?: IFilter;
};

type MapDataToArticleListingPropsParams = {
  listingResult: InfiniteQueryHookResult<InfiniteData<ArticleResponse>, Error>;
  filterAndSortingResult: QueryHookResult<FilterAndSortingResponse, Error>;
  eventListName: string;
  sortByParams: string | undefined;
};

export const mapDataToArticleListingProps = ({
  listingResult,
  filterAndSortingResult,
  eventListName,
  sortByParams,
}: MapDataToArticleListingPropsParams): ArticleListingProps => {
  const articles = listingResult.data?.pages.flatMap((page) => page.articles?.results || []) || [];
  const totalCount = listingResult.data?.pages.at(-1)?.articles?.resultsCount ?? 0;
  const sorting = filterAndSortingResult.data?.sorting;
  const filtering = filterAndSortingResult.data?.filtering;

  return {
    listing: {
      articles,
      totalCount,
      listName: eventListName,
    },
    pagination: {
      hasNextPage: listingResult.hasNextPage,
      fetchNextPage: listingResult.fetchNextPage,
      isFetchingNextPage: listingResult.isFetchingNextPage,
      hasPreviousPage: listingResult.hasPreviousPage,
      fetchPreviousPage: listingResult.fetchPreviousPage,
      isFetchingPreviousPage: listingResult.isFetchingPreviousPage,
    },
    sorting: {
      options: sorting?.options,
      selectedOption: sortByParams ?? sorting?.selected,
      isLoading: filterAndSortingResult.isLoading,
    },
    // TODO: This is a bit temporary, it should work the same as sorting (another refactoring needed for that)
    filter: <Filter filter={filtering} totalCount={totalCount} />,

    isLoading: listingResult.isLoading,
    isFetching: listingResult.isPlaceholderData,
  };
};
