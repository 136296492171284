import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { sendEvent } from '@aph/components/gtm/useGtm';
import {
  DropdownButton,
  type DropdownItem,
  type DropdownResult,
} from '@aph/ui/components/dropdown/dropdown';
import type { Option } from '../../../generated/ArticlesClient';
import { useGetSearchParams } from '../../../hooks/use-get-search-params/use-get-search-params';

export interface ArticleSortingProps {
  options: Option[] | undefined;
  selectedOption: string | undefined;
  isLoading: boolean;
}

const SORT_BY_KEY = 'sortBy';

const DEFAULT_OPTION = 'Popularitet';

// Hook created for testing the onSelected function, other solutions are welcomed.
export const useArticleSorting = ({
  options = [],
  selectedOption = '',
  isLoading,
}: ArticleSortingProps) => {
  const searchParams = useGetSearchParams();
  const router = useRouter();

  const items = options.map(
    (option): DropdownItem => ({
      title: option.name || '',
      value: option.value || '',
    }),
  );

  const getSelectedByValue = useCallback(
    (value: string) => {
      const defaultOption = {
        title: DEFAULT_OPTION,
        value: '',
      };
      return isLoading
        ? defaultOption
        : items.find((item) => item.value === value) || defaultOption;
    },
    [items, isLoading],
  );

  const selected = getSelectedByValue(selectedOption);

  const onSelected = (item: DropdownResult) => {
    const result = getSelectedByValue(item.value);
    searchParams.set(SORT_BY_KEY, result.value);
    router.push({ query: searchParams.toString() }, undefined, { shallow: true });
    sendEvent({ event: 'sort_products_click', sorting_option: result.value });
  };

  return { items, selected, onSelected };
};

export const ArticleSorting = ({ ...props }: ArticleSortingProps) => {
  const { items, selected, onSelected } = useArticleSorting(props);

  return <DropdownButton items={items} selected={selected} onSelected={onSelected} />;
};
